// == Cart
// -------------------------------

.cart {
	@include media($md) {
		display: table;

		margin: 0 0 30px;
	}

	margin: 0 -30px 30px;
}

.cart__item {
	@include media($md) {
		display: table-row;

		padding: 0;
	}

	display: flex;

	padding: 10px 30px;

	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	&:nth-child(2n) {
		background: #f5f5f5;
	}
}

.cart__pic {
	@include media($md) {
		display: table-cell;

		vertical-align: middle;
	}

	width: 90px;
	padding-right: 15px;
}

.cart__picHero {
	display: block;

	max-width: 100%;
	height: auto;
}

.cart__caption {
	@include media($md) {
		display: table-cell;

		width: auto;
		padding: 10px 20px 10px 0;

		vertical-align: middle;
	}

	width: calc(100% - 90px);
}

.cart__count {
	@include media($md) {
		display: table-cell;

		width: 112px;
		padding: 10px 20px 10px 0;

		vertical-align: middle;
	}

	padding: 10px 0 0;
}

.cart__price {
	@include media($md) {
		display: table-cell;

		width: auto;
		padding: 10px 20px 10px 0;

		text-align: right;
		vertical-align: middle;
	}

	width: calc(100% - 120px);
	padding: 10px 20px 0;

	white-space: nowrap;
}

.cart__remove {
	@include media($md) {
		display: table-cell;

		width: 48px;
		padding: 10px 20px 10px 0;

		vertical-align: middle;
	}

	padding: 10px 0 0;
}

.cart__removeLink {
	font-size: 16px;
	line-height: 28px;

	display: block;
	overflow: hidden;

	width: 28px;
	height: 28px;

	text-align: center;
	text-decoration: none;

	color: white;
	border-radius: 2px;
	background: $blue;
	&:before {
		font-family: iconfont;

		display: block;

		height: 100%;

		content: '\f00d';
	}
	&:hover {
		color: white;
		background: $darkblue;
	}
}
