// == Date
// -------------------------------

.date {
	font-size: 12px;

	display: block;

	margin-bottom: .5em;

	color: #686868;
}
