// == Container
// -------------------------------

.container {
	@include media($md) {
		flex-direction: row;
	}

	display: flex;
	flex-direction: column;

	max-width: 980px;
	margin: 0 auto 10px;
	padding: 30px;

	background: white;
}
