// == Images
// --------------------------------

.images {
	display: flex;

	margin: 0 -10px;

	flex-wrap: wrap;
}

.images__item {
	width: 50%;
	padding: 0 10px;
	&:first-child {
		width: 100%;
	}
	&:nth-child(n+2) {
		margin-top: 20px;
	}
}

.images__pic {
	display: block;

	max-width: 100%;
	height: auto;
}
