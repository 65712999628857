// == News
// -------------------------------

.news {
	@include last-margin;

	margin-bottom: 30px;
}

.news__item {
	@include clearfix;

	margin-bottom: 30px;
}

.news__hero {
	@include media($sm) {
		float: left;

		width: 32.78%;
		margin-bottom: 0;
	}

	margin-bottom: 20px;
}

.news__pic {
	display: block;

	max-width: 100%;
	height: auto;
}

.news__body {
	@include last-margin;
	@include media($sm) {
		float: right;

		width: 67.22%;
		padding-left: 30px;
	}
}

.news__caption {
	font-size: 18px;
	font-weight: bold;

	margin-bottom: 1em;
}
