// == Time error
// -------------------------------

.timeerror {
}

.timeerror__range {
	@include clearfix;

	margin: 0 -10px 15px;
}

.timeerror__item {
	float: left;

	width: 50%;
	padding: 0 10px 0 30px;
	&:before {
		line-height: 28px;

		display: block;
		float: left;

		margin-left: -20px;

		color: $darkblue;
	}
	&:nth-child(1):before {
		content: 'от';
	}
	&:nth-child(2):before {
		content: 'до';
	}
}

.ui-slider {
	position: relative;

	height: 6px;

	border: 1px solid #d4d4d4;
	border-radius: 3px;
}

.ui-slider-range {
	position: absolute;
	top: 0;

	height: 100%;

	border-radius: 2px;
	background: $blue;
}

.ui-slider-handle {
	position: static;
	position: absolute;

	display: block;

	width: 10px;
	height: 10px;
	margin: -3px 0 0 -5px;

	cursor: pointer;

	border-radius: 50%;
	outline: none;
	background: #334045;
}
