// == Features
// -------------------------------

.features {
	@include last-margin;

	font-size: 12px;

	margin-bottom: 30px;
}

.features__group {
	@include media($sm) {
		display: flex;

		align-items: flex-start;
	}

	margin-bottom: .75em;
}

.features__label {
	@include media($sm) {
		font-weight: normal;

		position: relative;

		width: 50%;
		margin-bottom: 0;
		padding-right: 10px;
		&:before {
			position: absolute;
			right: 2px;
			bottom: 3px;
			left: 0;

			display: block;

			content: '';

			border-bottom: 1px dotted $black;
		}
	}

	font-weight: bold;

	margin-bottom: .25em;
}

.features__labelText {
	@include media($sm) {
		position: relative;

		padding-right: 1px;

		background: white;
	}
}

.features__value {
	@include media($sm) {
		width: 50%;

		align-self: flex-end;
	}
}
