// == Total
// -------------------------------

.total {
	@include last-margin;

	display: flex;
	flex-direction: column;

	margin-bottom: 30px;

	align-items: flex-end;
	.cart + & {
		margin-top: -10px;
	}
}

.total__item {
	@include media($md) {
		margin-right: 68px;
	}

	display: flex;

	margin: 0 0 .5em;
}

.total__value {
	width: 105px;

	text-align: right;
}

.total__label {
	text-align: right;
}
