// == Spinner
// -------------------------------

.ui-spinner {
	position: relative;

	display: inline-block;

	width: 92px;
	padding: 0 28px;
}

.ui-spinner-button {
	font-size: 16px;
	line-height: 28px;

	position: absolute;
	top: 0;

	display: block;
	overflow: hidden;

	width: 28px;
	height: 28px;

	cursor: pointer;
	text-align: center;
	text-decoration: none;

	color: white;
	border-radius: 2px;
	background: $blue;
	&:before {
		font-family: iconfont;
	}
	&:hover {
		color: white;
		background: $darkblue;
	}
}

.ui-spinner-down {
	left: 0;
	&:before {
		content: '\f068';
	}
}

.ui-spinner-up {
	right: 0;
	&:before {
		content: '\f067';
	}
}

.ui-spinner-input {
	font-size: 14px;
	line-height: 18px;

	display: block;

	width: 100%;
	height: 28px;
	padding: 5px;

	text-align: center;

	border: none;
	outline: none;
	background: none;
}
