// == Benefits
// -------------------------------

.benefits {
	@include media($md) {
		display: flex;

		margin: -12px -6px 30px;

		flex-wrap: wrap;
	}

	margin: -12px 0 30px;
}

.benefits__item {
	@include media($md) {
		width: calc(50% - 12px);
		margin: 12px 6px 0;
	}

	font-size: 12px;
	font-weight: bold;

	position: relative;

	display: flex;

	min-height: 50px;
	margin: 12px 0 0;
	padding: 10px 10px 10px 50px;

	color: white;
	background: #887466;

	align-items: center;
	&:before {
		font-family: iconfont;
		font-size: 26px;
		font-weight: normal;
		line-height: 30px;

		position: absolute;
		top: 10px;
		left: 10px;

		width: 30px;
		height: 30px;

		text-align: center;
	}
	&-price:before {
		content: '\f1c0';
	}
	&-location:before {
		content: '\f041';
	}
	&-official:before {
		content: '\f164';
	}
}
