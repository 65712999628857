// == Banners
// -------------------------------

.banners {
	position: relative;

	max-width: 980px;
	margin: 0 auto 10px;
}

.banners__pic {
	display: block;

	max-width: 100%;
	height: auto;
}

.banners__pagination {
	@include clearfix;

	position: absolute;
	z-index: 2;
	right: 10px;
	bottom: 10px;
}

.banners__bullet {
	display: block;
	float: left;

	width: 10px;
	height: 10px;
	margin-left: 5px;

	cursor: pointer;

	border-radius: 2px;
	background: $blue;
	&_active {
		cursor: default;

		background: white;
	}
}
