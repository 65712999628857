// == Nav
// -------------------------------

.nav {
	@include clearfix;
	@include media($md) {
		width: calc(100% + 14px);
		margin: 0 -7px;
		padding-top: 10px;

		order: 3;
	}
	@include media($lg) {
		width: auto;
		padding-top: 0;

		order: 1;
	}

	position: relative;
	z-index: 3;
}

.nav__toggle {
	@include media($md) {
		display: none;
	}

	position: relative;

	float: right;

	width: 27px;
	height: 21px;
	margin: 6px 0;

	cursor: pointer;
	&:before,
	&:after,
	& > i {
		position: absolute;
		left: 0;

		display: block;

		width: 100%;
		height: 5px;

		content: '';
		transition: all .15s ease;

		border-radius: 2px;
		background: $darkblue;
	}
	&:before {
		top: 0;
	}
	&:after {
		top: 16px;
	}
	& > i {
		top: 8px;
	}
	.nav_open & {
		&:before {
			top: 8px;

			transform: rotate(45deg);
		}
		&:after {
			top: 8px;

			transform: rotate(-45deg);
		}
		& > i {
			opacity: 0;
		}
	}
}

.nav__list {
	@include reset-list;
	@include clearfix;
	@include media($md) {
		display: block;

		margin: 0;

		text-align: left;

		background: none;
	}

	display: none;
	clear: both;

	margin: 0 -30px;

	text-align: right;

	background: #f8f8f8;
	.nav_open & {
		display: block;
	}
}

.nav__item {
	@include media($md) {
		line-height: 31px;

		position: relative;

		float: left;

		margin-right: 2px;
		padding: 0 6px 1px;

		border: 1px solid transparent;
		border-bottom: 0;
	}
	&:last-child {
		margin-right: 0;
	}
	&_open {
		@include media($md) {
			border-color: #484848;
			border-radius: 2px 2px 0 0;
			background: none;
			&:after {
				position: absolute;
				top: 100%;
				right: 0;
				left: 0;

				display: block;

				height: 1px;

				content: '';

				background: white;
			}
		}

		background: #f0f0f0;
	}
}

.nav__link {
	@include media($md) {
		display: inline;

		padding: 0;
	}

	font-family: $ubuntucond;
	font-size: 18px;

	display: block;

	padding: 5px 30px;

	text-decoration: none;
	&-sm:after {
		@include media($md) {
			@include triangle(8px 6px, #b5b5b5, down);

			display: inline-block;

			margin: 15px 0 0 .25em;

			content: '';
			vertical-align: top;
		}
	}
}

.nav__linkText {
	.nav__link:hover & {
		text-decoration: underline;
	}
	.nav__link-sm & {
		border-bottom: 1px dotted;
	}
	.nav__link-sm:hover & {
		text-decoration: none;
	}
}

.nav__sm {
	@include reset-list;
	@include last-margin;
	@include media($md) {
		position: absolute;
		top: 100%;
		left: -1px;

		min-width: calc(100% + 2px);
		padding: 10px;

		border: 1px solid #484848;
		border-radius: 0 0 2px 2px;
		background: white;
	}

	display: none;
	.nav__item_open & {
		display: block;
	}
}

.nav__smItem {
	@include media($md) {
		margin-bottom: 10px;
	}

	font-family: $ubuntucond;
	font-size: 14px;
	line-height: 1;

	white-space: nowrap;
}

.nav__smLink {
	@include media($md) {
		display: inline;

		padding: 0;
	}

	display: block;

	padding: 5px 30px;

	color: $blue;
	&:hover {
		color: $darkblue;
	}
}
