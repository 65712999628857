// == Paging
// -------------------------------

.paging {
	display: flex;

	margin-bottom: 30px;

	flex-wrap: wrap;
	justify-content: center;
}

.paging__caption {
	line-height: 30px;

	margin: 0 4px;
}

.paging__item {
	font-weight: bold;
	line-height: 28px;

	width: 30px;
	height: 30px;
	margin: 0 4px;

	text-align: center;

	border: 1px solid #6e6e6e;
	background: #d7d7d7;
}

.paging__link {
	display: block;

	text-decoration: none;

	color: white;
	background: linear-gradient(to bottom, #185268, #0084b6);
	&:hover {
		color: white;
		background: #0084b6;
	}
}
