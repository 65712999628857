// == Product
// -------------------------------

.product {
	@include clearfix;

	margin-bottom: 30px;
}

.product__photos {
	@include media($sm) {
		float: left;

		width: 32.78%;
		margin-bottom: 0;
	}

	max-width: 200px;
	margin-bottom: 20px;
}

.product__body {
	@include last-margin;
	@include media($sm) {
		float: right;

		width: 67.22%;
		padding-left: 30px;
	}
}

.product__article {
	font-size: 12px;

	display: block;

	margin-bottom: 1em;

	color: #686868;
}
