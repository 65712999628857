// == Articles
// -------------------------------

.articles {
	@include last-margin;

	margin-bottom: 30px;
}

.articles__title {
	font-size: 18px;
	font-weight: bold;

	margin-bottom: 1em;
}

.articles__list {
	@include last-margin;

	margin-bottom: 1.5em;
}

.articles__item {
	margin-bottom: 1em;
}

.articles__comments {
	font-size: 12px;

	color: #686868;
}

.articles__more {
	color: #2c6f8c;
}
