// == Footer
// -------------------------------

.footer {
	background: white;
}

.footer__inner {
	@include last-margin;

	display: flex;

	max-width: 980px;
	margin-right: auto;
	margin-left: auto;
	padding: 10px 30px;

	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
}

.footer__logo {
	font-family: $ubuntucond;
	font-size: 14px;

	display: block;

	padding-left: 40px;

	text-decoration: none;

	color: $darkblue;
	background: url(../i/logo.png) no-repeat 0 50%;
	background-size: 36px 11px;
	&:hover {
		color: $darkblue;
	}
}

.footer__copyright {
	font-size: 12px;

	color: #686868;
}

.footer__contacts {
	@include media($lg) {
		width: auto;
		margin: 0;
	}

	font-size: 12px;

	width: 100%;
	margin: 10px 0;
}

.footer__tel {
	font-weight: bold;

	white-space: nowrap;

	color: $darkblue;
}
