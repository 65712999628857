// == Topbar
// -------------------------------

.topbar {
	position: relative;

	background: white;
	&:before {
		position: absolute;
		left: 0;

		display: block;

		width: 50%;
		height: 100%;

		content: '';

		background: #f2f2f2;
	}
}

.topbar__inner {
	position: relative;

	max-width: 980px;
	margin-right: auto;
	margin-left: auto;
	padding: 10px 30px;

	text-align: right;

	background: url(../i/topbar.png) repeat-y 50% 0;
}
