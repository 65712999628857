// == Popup
// --------------------------------

.overlay {
	position: fixed;
	z-index: 99;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: none;
	overflow-y: auto;

	transition: opacity .3s ease;

	opacity: 0;
	background: rgba(0,0,0,.6);
	&_visible {
		opacity: 1;
	}
}

.overlayed {
	overflow: hidden;
}

.popup {
	@include last-margin;

	position: absolute;
	left: 50%;

	display: none;

	width: 300px;
	margin-bottom: 30px;
	margin-left: -150px;
	padding: 20px;

	transition: transform .3s ease, opacity .3s ease;
	transform: scale(.75);

	opacity: 0;
	background: #f3f3f3;
	&_visible {
		transform: scale(1);

		opacity: 1;
	}
}

.popup__title {
	font-size: 18px;
	font-weight: bold;

	margin-bottom: 1em;

	text-align: center;
}

.popup__close {
	font-size: 16px;
	line-height: 20px;

	position: absolute;
	top: 5px;
	right: 5px;

	display: block;

	width: 20px;
	height: 20px;
	margin: 0;
	padding: 0;

	text-align: center;

	color: $black;
	border: none;
	outline: none;
	background: none;
	&:before {
		font-family: iconfont;

		content: '\f00d';
	}
}
