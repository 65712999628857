// == Fonts
// -------------------------------

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700,700italic|Ubuntu+Condensed&subset=latin,cyrillic);
@include font-face(iconfont, '../fonts/icomoon');

[class^='icon-'],
[class*=' icon-'] {
    font-family: iconfont;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;

    display: inline-block;

    text-align: center;
    text-decoration: inherit;
    text-transform: none;

    speak: none;
}

.icon-close:before {
    content: '\f00d';
}
.icon-remove:before {
    content: '\f00d';
}
.icon-times:before {
    content: '\f00d';
}
.icon-map-marker:before {
    content: '\f041';
}
.icon-chevron-left:before {
    content: '\f053';
}
.icon-chevron-right:before {
    content: '\f054';
}
.icon-plus:before {
    content: '\f067';
}
.icon-minus:before {
    content: '\f068';
}
.icon-angle-left:before {
    content: '\f104';
}
.icon-angle-right:before {
    content: '\f105';
}
.icon-thumbs-up:before {
    content: '\f164';
}
.icon-database:before {
    content: '\f1c0';
}
