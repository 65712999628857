// == Form
// -------------------------------

// Button
.btn {
	font-size: 14px;
	line-height: 18px;

	display: inline-block;

	min-width: 120px;
	margin: 0;
	padding: 5px 10px;

	text-align: center;
	text-decoration: none;

	color: white;
	border: none;
	border-radius: 2px;
	outline: none;
	background: $blue;
	&:hover {
		color: white;
		background: $lightblue;
	}
	&-wide {
		width: 100%;
	}
	&_disabled {
		cursor: default;

		background: #6e6e6e;
		&:hover {
			background: #6e6e6e;
		}
	}
}

// Input & textarea
.input,
.textarea {
	font-size: 14px;
	line-height: 18px;

	display: block;

	width: 100%;
	height: 28px;
	margin: 0;
	padding: 4px 9px;

	border: 1px solid #d7d7d7;
	outline: none;
	background: white;
	&::placeholder {
		color: #686868;
	}
}

.textarea {
	height: auto;
}

// Form layout
.form {
	margin-bottom: 30px;
}

.form__group {
	margin-bottom: 20px;
}

.form__columns {
	@include last-margin;
	@include media($sm) {
		@include clearfix;

		margin: 0 -15px 20px;
	}

	margin-bottom: 20px;
}

.form__column {
	@include last-margin;
	@include media($sm) {
		float: left;

		width: 50%;
		margin-bottom: 0;
		padding: 0 15px;
	}

	margin-bottom: 20px;
}

.form__footer {
	text-align: center;
}
