// == Brands
// -------------------------------

.brands {
	position: relative;

	max-width: 980px;
	margin: 0 auto 10px;
	padding: 20px 30px;

	background: white;
}

.brands__item {
	@include media(min-width 400px) {
		width: 50%;
	}
	@include media(min-width 570px) {
		width: 33.3333%;
	}
	@include media(min-width 740px) {
		width: 25%;
	}
	@include media(min-width 910px) {
		width: 20%;
	}

	width: 100%;

	align-self: center;
}

.brands__pic {
	display: block;

	max-width: 100%;
	height: auto;
	margin-right: auto;
	margin-left: auto;
}

.brands__prev,
.brands__next {
	font-size: 16px;
	line-height: 30px;

	position: absolute;
	top: 50%;

	width: 30px;
	height: 30px;
	margin-top: -15px;

	cursor: pointer;
	text-align: center;

	color: #cacaca;
	&:before {
		font-family: iconfont;
	}
	&:hover {
		color: $lightblue;
	}
}

.brands__prev {
	left: 0;
	&:before {
		content: '\f053';
	}
}

.brands__next {
	right: 0;
	&:before {
		content: '\f054';
	}
}
