// == Header
// -------------------------------

.header {
	@include media($md) {
		margin-bottom: 10px;
	}

	margin-bottom: 63px;

	background: white;
}

.header__inner {
	@include media($md) {
		display: flex;

		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	position: relative;

	max-width: 980px;
	margin-right: auto;
	margin-left: auto;
	padding: 14px 30px;
}

.header__logo {
	@include media($md) {
		position: static;

		order: 0;
	}

	font-family: $ubuntucond;
	font-size: 24px;
	line-height: 1;

	position: absolute;
	top: 20px;
	left: 30px;

	display: block;

	padding-left: 74px;

	text-decoration: none;

	color: $darkblue;
	background: url(../i/logo.png) no-repeat 0 50%;
	&:hover {
		color: $darkblue;
	}
}

.header__cart {
	@include media($md) {
		position: static;

		order: 1;
	}
	@include media($lg) {
		order: 2;
	}

	font-size: 12px;
	font-weight: bold;
	line-height: 33px;

	position: absolute;
	top: calc(100% + 15px);

	width: 33px;
	height: 33px;
	padding-left: 14px;

	text-decoration: none;

	color: white;
	border-radius: 3px;
	background: $blue url(../i/cart.png) no-repeat 0 50%;
	&:hover {
		color: white;
		background-color: $lightblue;
	}
}

.header__tel {
	@include media($md) {
		position: static;

		order: 2;
	}
	@include media($lg) {
		order: 3;
	}

	font-family: $ubuntucond;
	font-size: 24px;
	line-height: 1;
	line-height: 33px;

	position: absolute;
	top: calc(100% + 15px);
	left: 100px;

	color: black;
}
