// == Tabs
// -------------------------------

.tabs {
	margin-bottom: 30px;
}

.tabs__list {
	@include reset-list;
	@include media($md) {
		display: flex;
	}

	margin: 0 0 20px;

	border-bottom: 5px solid $blue;
}

.tabs__item {
	@include media($md) {
		margin-bottom: 0;

		flex-grow: 1;
	}

	margin-bottom: 5px;

	outline: none;
}

.tabs__link {
	@include media($md) {
		display: inline-block;

		padding-bottom: 5px;
	}

	outline: none;
	.ui-tabs-active & {
		@include media($md) {
			margin-bottom: -5px;

			border-bottom: 5px solid #8eb2c1;
		}

		cursor: default;
		text-decoration: none;

		color: black;
		&:hover {
			color: black;
		}
	}
}

.tabs__content {
	@include last-margin;
}

.ui-tabs-hide {
	display: none;
}
