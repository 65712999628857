// == Content
// -------------------------------

.content {
	@include last-margin;
	@include media($md) {
		width: 69.57%;
		margin-bottom: 0;
		padding-left: 30px;

		order: 1;
	}

	margin-bottom: 30px;

	order: 0;
}

.img-left {
	@include media($sm) {
		float: left;

		margin-right: 30px;
	}

	display: block;

	margin-bottom: .125em;
}

.img-right {
	@include media($sm) {
		float: right;

		margin-left: 30px;
	}

	display: block;

	margin-bottom: .125em;
}
