// == Products
// -------------------------------

.products {
	@include last-margin;

	margin-bottom: 30px;
}

.products__item {
	@include clearfix;

	margin-bottom: 30px;
}

.products__hero {
	@include media($sm) {
		float: left;

		width: 32.78%;
		margin-bottom: 0;
	}

	margin-bottom: 20px;
}

.products__pic {
	display: block;

	max-width: 100%;
	height: auto;
}

.products__body {
	@include last-margin;
	@include media($sm) {
		float: right;

		width: 67.22%;
		padding-left: 30px;
	}
}

.products__type {
	font-size: 16px;
}

.products__caption {
	font-size: 18px;
	font-weight: bold;

	margin-bottom: 1em;
}
