// == Base
// -------------------------------

*,
:before,
:after {
	box-sizing: border-box;
}

html {
	height: 100%;

	background: #e2e2e2 url(../i/bg.png) repeat 0 0;
}

body {
	@include media($lg) {
		font-size: 14px;

		background: url(../i/sinus.png) repeat-x 50% 175px fixed;
	}

	font-family: $tahoma;
	font-size: 12px;
	line-height: 1.25;

	display: flex;
	flex-direction: column;

	min-height: 100%;

	color: $black;

	justify-content: space-between;
}

a {
	color: $darkblue;
	&:hover {
		color: $blue;
	}
}

p {
	margin: 0 0 1.25em;
}

h1 {
	font-size: 18px;
	font-weight: bold;

	margin: 0 0 1em;
}

h2 {
	font-size: 16px;
	font-weight: bold;

	margin: 0 0 1em;
}

h3 {
	font-size: 14px;
	font-weight: bold;

	margin: 0 0 1em;
}
