// == Promo
// -------------------------------

.promo {
	@include media($md) {
		display: flex;

		background: white url(../i/promo.png) no-repeat 50% 100%;

		justify-content: space-between;
	}

	max-width: 980px;
	margin: 0 auto 10px;
	padding: 15px 30px;

	background: white;
}

.promo__content {
	@include media($md) {
		display: flex;
	}

	display: none;

	width: calc(100% - 330px);
	padding: 5px 0;

	align-items: center;
	justify-content: center;
}

.promo__item {
	display: none;

	align-items: center;
	justify-content: center;
	&_active {
		display: flex;
	}
}

.promo__pic {
	display: block;

	max-width: 100%;
	height: auto;
	margin-right: auto;
	margin-left: auto;
}

.promo__nav {
	@include media($md) {
		width: 300px;
	}

	display: flex;
	flex-direction: column;
}

.promo__navItem {
	@include last-margin;

	position: relative;

	display: flex;
	flex-direction: column;

	width: 100%;
	margin: 5px 0;
	padding: 20px;

	text-decoration: none;

	color: white;
	background: $blue;

	flex-grow: 1;
	justify-content: center;
	&:before {
		@include media($md) {
			@include triangle(20px 18px, $lightblue, left);

			position: absolute;
			top: 50%;
			right: 100%;

			display: block;

			margin-top: -10px;

			content: '';

			opacity: 0;
		}
	}
	&:hover {
		color: white;
	}
	&_active {
		background: $lightblue;
		&:before {
			opacity: 1;
		}
	}
}

.promo__caption {
	font-size: 14px;
	font-weight: bold;

	margin-bottom: 1em;
}

.promo__text {
	font-size: 12px;
}
