@charset 'UTF-8';

// Fonts
$tahoma: Tahoma, sans-serif;
$ubuntucond: 'Ubuntu Condensed', sans-serif;
$opensans: 'Open Sans', sans-serif;

// Colors
$black: #222;
$darkblue: #1d6584;
$blue: #0085b8;
$lightblue: #00a0dd;

// Breakpoints
$lg: min-width 980px;
$md: min-width 768px;
$sm: min-width 480px;