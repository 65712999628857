// == Crumbs
// -------------------------------

.crumbs {
	font-size: 12px;

	margin-bottom: 1em;
}

.crumbs__item {
	display: inline-block;
	&:after {
		font-family: iconfont;
		font-size: 15px;
		line-height: 1;

		margin: 0 .25em 0 .5em;

		content: '\f105';
		vertical-align: top;
	}
	&:last-child:after {
		display: none;
	}
}
