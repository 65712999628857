// == Price
// -------------------------------

.price {
	display: flex;

	margin-bottom: 30px;

	align-items: flex-end;
	flex-wrap: wrap;
}

.price__caption {
	font-size: 14px;
	line-height: 24px;

	margin-right: 10px;
	padding-top: 4px;

	color: #686868;
}

.price__value {
	width: 120px;
}

.price__current {
	font-size: 18px;
	font-weight: bold;
	line-height: 28px;
}

.price__old {
	font-size: 14px;

	text-decoration: line-through;
}
