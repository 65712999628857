// == Callorder
// -------------------------------

.callorder {
	font-size: 12px;

	text-decoration: none;

	border-bottom: 1px dotted;
	&:hover {
		color: $blue;
	}
}
