// == Search
// -------------------------------

.search {
	position: relative;

	margin-bottom: 30px;
	padding-right: 28px;

	border: 1px solid #d7d7d7;
}

.search__input {
	font-size: 14px;
	line-height: 18px;

	display: block;

	width: 100%;
	height: 26px;
	padding: 4px 9px;

	border: none;
	outline: none;
}

.search__btn {
	font-size: 20px;
	line-height: 26px;

	position: absolute;
	top: 0;
	right: 0;

	overflow: hidden;

	width: 26px;
	height: 26px;
	margin: 0;
	padding: 0;

	text-align: center;

	color: $darkblue;
	border: none;
	outline: none;
	background: #d7d7d7;
	&:before {
		font-family: iconfont;

		display: block;

		width: 100%;
		height: 100%;

		content: '\f105';
	}
}
