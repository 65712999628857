// == Filter
// -------------------------------

.filter {
	@include last-margin;
	@include media($md) {
		display: block;
	}

	display: none;

	margin: 0 0 30px;
	padding: 20px 20px 30px;

	border: 1px solid $lightblue;
	background: white url(../i/filter.jpg) no-repeat 50% 0;
	background-size: 100% auto;
}

.filter__title {
	font-size: 18px;
	font-weight: bold;

	margin-bottom: 20px;
}

.filter__group {
	@include last-margin;

	margin-bottom: 30px;
}

.filter__caption {
	font-size: 16px;

	margin-bottom: 8px;
}

.filter__columns {
	@include clearfix;

	margin: 0 -10px 30px;
}

.filter__column {
	@include last-margin;

	float: left;

	width: 50%;
	padding: 0 10px;
}
