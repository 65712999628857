// == Checklist
// -------------------------------

.checklist {
	margin-bottom: 20px;
}

.checklist__item {
	margin-bottom: .25em;
	padding-left: 20px;
}

.checklist__checkbox,
.checklist__radio {
	float: left;

	margin: 3px 0 0 -20px;
}

.checklist__label {
	color: $darkblue;
}
