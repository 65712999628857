// == Sidebar
// -------------------------------

.sidebar {
	@include last-margin;
	@include media($md) {
		width: 30.43%;

		order: 0;
	}

	order: 1;
}
